import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";

import edit from "../../images/edit.svg";
import Delete from "../../images/delete.svg";
import Logo from "../../images/logo-2.png";
import { useSelector } from "react-redux";
import api from "../../api";
import { Pagination, Stack } from "@mui/material";
import { Loader } from "./loader";
import { Modal } from "react-bootstrap";
import { AddMarket } from "./addMarket";

export function AllAdminMarket({ match }) {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [data, setData] = useState([]);
  const [noOfPost, setnoOfPost] = useState(0);
  const [loader, setLoader] = useState(true);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // console.log(window.location);
  // console.log(match.url);
  const getPosts = async () => {
    try {
      let payload = {
        page: page,
      };
      setLoader(true);
      const postData = await api.martketUpdateGetAdmin(payload);
      console.log(postData);
      setData(postData.post);
      setnoOfPost(postData.count);
      setLimit(postData.limit);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  const deletePost = async (id) => {
    try {
      let payload = {
        id: id,
      };
      setLoader(true);
      const isConfirmed = window.confirm(
        "Are you sure you want to delete this item?"
      );
      if (isConfirmed) {
        const userData = await api.martketUpdateDelete(payload);
        console.log("deleted");
        getPosts();
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getPosts();
  }, [page]);

  return (
    <>
      {/* Begin Page Content */}
      <div className="container-fluid">
        {/* Content Row */}
        <div className="row">
          {/* Area Chart */}
          <div className="col">
            <div className="row mx-0 drivers-row">
              <div className="col-12 d-flex justify-content-between pr-0 flex-wrap">
                <h4 className="color-dark fs-17 dib font-weight-bold mr-5">
                  Market Updates ({noOfPost})
                </h4>
                <button className="themeBtn" onClick={handleShow}>
                  Add Market Updates
                </button>
              </div>
            </div>
            {/* table */}
            <Table
              data={data}
              setPage={setPage}
              page={page}
              noOfPost={noOfPost}
              limit={limit}
              deletePost={deletePost}
            />
          </div>
        </div>
      </div>
      {/* /.container-fluid */}
      {/* /.container-fluid */}
      <Modal show={show} size="lg" onHide={handleClose} centered className="">
        <Modal.Body>
          <div className="otpModalMain">
            <div className="closeIcon" onClick={handleClose}>
              <i className="fa-solid fa-xmark" />
            </div>
            <AddMarket getPosts={getPosts} setShow={setShow} />
          </div>
        </Modal.Body>
      </Modal>
      {loader && <Loader />}
    </>
  );
}
const Table = ({ data, page, setPage, noOfPost, limit, deletePost }) => {
  // const authedUser = useSelector(s => s.authedUser.authedUser);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="card sortby-box br-10 border-transparent mt-3">
        <div className="card-header br-10  bg-white border-transparent py-3 ">
          <div className="table-responsive">
            <table className="table table-hover drivers-table">
              <thead>
                <tr>
                  <th scope="col" className="">
                    Image
                  </th>
                  <th scope="col" className="">
                    Video
                  </th>
                  <th scope="col" className="">
                    Voice
                  </th>
                  <th scope="col" className="">
                    PDF File
                  </th>
                  <th scope="col" className="">
                    Description
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => (
                  <tr key={key}>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        <div className="pImage" style={{ maxWidth: 130 }}>
                          {item?.imageTitle ? (
                            <img
                              src={`${process.env.REACT_APP_S3URL}/img_${item.imageTitle}.${item.imageFormat}`}
                              alt=""
                            />
                          ) : (
                            "-"
                          )}
                        </div>
                      </span>
                    </td>
                    <td>
                      {item?.video ? (
                        <span className="table-text-dark db iframeVideoAdmin ">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.video,
                            }}
                          />
                        </span>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <span className="table-text-dark w-max-c db">
                        <div className="pImage">
                          {item?.voice ? (
                            <audio controls>
                              <source
                                src={`${process.env.REACT_APP_S3URLVoice}/${item.voice}`}
                              />
                              Audio element not supported by your browser.
                            </audio>
                          ) : (
                            "-"
                          )}
                        </div>
                      </span>
                    </td>
                    <td>
                      {item?.pdfFile ? (
                        <div>
                          <a
                            href={`${process.env.REACT_APP_S3URL}/${item?.pdfFile}`}
                            download={item?.pdfFile.split("_")[0]}
                            target="_blank"
                            className="pdfFileCard"
                          >
                            {item?.pdfFile.includes(".pdf") ? (
                              <i className="fa-solid fa-file-pdf me-2 pdf" />
                            ) : (
                              <i className="fas fa-file-word me-2 word" />
                            )}
                            {item?.pdfFile.split("_")[0]}
                          </a>
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>
                      <span className="table-text-dark db iframeVideoAdmin ">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                        />
                      </span>
                    </td>

                    <td>
                      <div className="mr-5 w-40">
                        {/* <a href="#" className="mr-3"><img src={edit} alt="edit" /></a> */}
                        <a
                          className="cursor-pointer"
                          onClick={() => deletePost(item?._id)}
                        >
                          <img src={Delete} alt="Delete" />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(noOfPost / limit)}
                page={page}
                onChange={handleChange}
              />
            </Stack>
          </div>
        </div>
      </div>
    </>
  );
};
